import { ResultAsync } from 'neverthrow';
import { ErrorResult } from '../configuration/error-result';
import { gql } from '@apollo/client';
import client from '../../apollo-client';
import { FetchCurrentUser } from '../graphql/generated/types';

interface FetchCurrentUserProps {
  authToken: string | undefined;
}

/**
 * Fetches the current user
 *
 * @param variables - query parameters
 * @returns result or error
 */
export function fetchCurrentUser(variables: FetchCurrentUserProps) {
  return ResultAsync.fromPromise(
    client.query<FetchCurrentUser>({
      query: gql`
        fragment FetchCurrentUser_viewer on User {
          first_name
          last_name
          email
          id
          active_subscription
        }
        query FetchCurrentUser {
          viewer {
            ...FetchCurrentUser_viewer
          }
        }
      `,
      variables: {
        authToken: variables.authToken,
      },
      fetchPolicy: 'no-cache',
    }),
    (error) =>
      ErrorResult.create({
        name: 'FETCH_CURRENT_USER_FAILED',
        message: 'Failed to fetch current user',
        level: 'error',
        error,
      }),
  );
}
