import { deleteCookie } from 'cookies-next';
import { NextRouter } from 'next/router';
import { resetMixpanel } from '../../../lib/mixpanel';

/**
 * Logs the user out of the application.
 * (and redirects to the Rails app, with a logout query param)
 */
export function logout(router: NextRouter) {
  // Delete the cookie which contains the auth token (aka. logging out the user)
  deleteCookie('auth_token');
  resetMixpanel();
  router.push('/login');
}
