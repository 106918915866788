import React, { useState, useEffect } from 'react';
import '../styles/globals.css';
import '../components/date-time-field/date-time-field.css';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import client from '../../apollo-client';
import { AppContext, AppProps } from 'next/app';
import Resources from '../lang.json';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import '../styles/nprogress.css';
import { PageLoadingContext } from '../configuration/page-loading-context';
import Utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
import Dayjs from 'dayjs';
import * as Sentry from '@sentry/nextjs';
import { fetchCurrentUser } from '../utils/fetch-current-user';
import { getAuthToken } from '../utils/get-auth-token/get-auth-token';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith/isomorphic';
import { IState } from 'flagsmith/types';
import { initFlagsmith } from '../utils/init-flagsmith';
import { ShareASale } from '../components/share-a-sale/share-a-sale';
import ErrorBoundary from '../components/error-boundary/error-boundary';
import { Toast } from '../components/toast/toast';

// Import Mixpanel utilities
import {
  initMixpanel,
  trackEvent,
  setGlobalProperties,
} from '../../lib/mixpanel';
import { extractRailsId } from '../utils/extract-rails-guid/extract-rails-guid';

type AppRootProps = {
  flagsmithState: IState;
  // We will pass user to pageProps from getInitialProps
  pageProps: AppProps['pageProps'] & {
    user?: {
      id: string;
      email: string;
      first_name?: string;
      last_name?: string;
      active_subscription?: string | null;
    } | null;
  };
} & AppProps;

Dayjs.extend(Timezone);
Dayjs.extend(Utc);

i18n.use(initReactI18next).init({
  resources: Resources,
  lng: 'en',
  interpolation: {
    escapeValue: false, // react already safeguards from xss
  },
});

function MyApp({
  Component,
  pageProps,
  flagsmithState,
}: AppRootProps): JSX.Element {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  // Pull user from pageProps
  const { user, ...restPageProps } = pageProps;

  const handleLoadStart = () => {
    NProgress.start();
    setLoading(true);
  };

  const handleLoadDone = () => {
    NProgress.done();
    setLoading(false);
  };

  usePageLoader();
  useMixpanel(user);

  return (
    <ErrorBoundary>
      <PageLoadingContext.Provider
        value={{ showLoading: handleLoadStart, hideLoading: handleLoadDone }}
      >
        {loading ? (
          <div className="bg-black w-screen h-full fixed z-999 opacity-10" />
        ) : null}

        <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
          <ApolloProvider client={client}>
            <Component {...restPageProps} />
          </ApolloProvider>
          <ShareASale />
        </FlagsmithProvider>

        <div
          id="editor"
          className="fixed top-0 left-0 right-0 bottom-0 z-999"
          style={{ display: 'none' }}
        />
        <Toast />
      </PageLoadingContext.Provider>
    </ErrorBoundary>
  );

  /**
   * Handles page route changes for showing loading state.
   */
  function usePageLoader() {
    useEffect(() => {
      NProgress.configure({ speed: 500 });

      router.events.on('routeChangeStart', handleLoadStart);
      router.events.on('routeChangeComplete', handleLoadDone);
      router.events.on('routeChangeError', handleLoadDone);

      return () => {
        router.events.off('routeChangeStart', handleLoadStart);
        router.events.off('routeChangeComplete', handleLoadDone);
        router.events.off('routeChangeError', handleLoadDone);
      };
    }, []);
  }

  /**
   * Initialize Mixpanel, set user global props, and track page views
   */
  function useMixpanel(currentUser?: AppRootProps['pageProps']['user']) {
    // 1) Initialize Mixpanel once on mount
    useEffect(() => {
      const token = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
      if (token) {
        initMixpanel(token);
      }
    }, []);

    // 2) Whenever the user changes (or first appears), set global properties
    useEffect(() => {
      if (currentUser && currentUser.id) {
        setGlobalProperties({
          user_id: extractRailsId(currentUser.id),
          subscription_type: currentUser.active_subscription ?? null,
          user_email: currentUser.email ?? null,
          logged_in: true,
          device_platform: 'web',
        });
      }
    }, [currentUser]);

    // 3) Track route changes (page views)
    useEffect(() => {
      const handleRouteChange = (url: string) => {
        trackEvent('page_view', { page_name: url });
      };

      router.events.on('routeChangeComplete', handleRouteChange);
      // Fire once on first load
      handleRouteChange(router.asPath);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, []);
  }
}

MyApp.getInitialProps = async (context: AppContext) => {
  const initFlagsmithFn = initFlagsmith(flagsmith);
  await initFlagsmithFn.unwrapOr(null);

  const { req, res } = context.ctx;
  if (!req || !res) {
    return { flagsmithState: flagsmith.getState() };
  }

  const authToken = getAuthToken(req, res);

  let user = null;

  if (authToken) {
    const fetchUserFn = fetchCurrentUser({ authToken });
    const userResult = await fetchUserFn.unwrapOr(null);

    if (userResult) {
      user = userResult.data?.viewer;
      if (user) {
        const username = `${user.first_name} ${user.last_name}`;
        Sentry.setUser({ email: user.email, username, id: user.id ?? '' });
      }
    }
  }

  // get the existing pageProps (if any) from the wrapped _app
  const appInitialProps = await (context.AppTree as any)?.getInitialProps?.(
    context,
  );
  // or use the simpler approach:  const appInitialProps = await App.getInitialProps(context);

  return {
    flagsmithState: flagsmith.getState(),
    pageProps: {
      ...appInitialProps?.pageProps,
      user,
    },
  };
};

export default MyApp;
